import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import ErrorBoundary from './Error/index.js';
import "./Assets/stylesheets/style.css"


// Import des différents composants de page
const AccueilLazy = lazy(() => import('./Accueil/index.js'));
const SocieteLazy = lazy(() => import('./Societe/index.js'));
const FamilleLazy = lazy(() => import('./Famille/index.js'));
const ProduitLazy = lazy(() => import('./Produit/index.js'));
const FicheLazy = lazy(() => import('./Fiche/index.js'));
const BlogLazy = lazy(() => import('./Blog/index.js'));
const ArticleLazy = lazy(() => import('./Blog/Articles/index.js'));
const DevisLazy = lazy(() => import('./Devis/index.js'));
const ContactLazy = lazy(() => import('./Contact/index.js'));
const PolitiqueLazy = lazy(() => import('./Politique/index.js'));
const MentionsLazy = lazy(() => import('./Mentions/index.js'));
const CookiesLazy = lazy(() => import('./Cookies/index.js'));
const RemerciementLazy = lazy(() => import("./Merci/index.js"))
const SandBoxLazy = lazy(() => import("./SandBox/SandBox.js"))
const SandBoxLazy2 = lazy(() => import("./SandBox/SandBox2.js"))

function App() {

  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Suspense>
        <AccueilLazy />
      </Suspense>
    },
    {
      path: "/sandbox",
      element: <Suspense>
        <SandBoxLazy />
      </Suspense>
    },
    {
      path: "/sandbox2",
      element: <Suspense>
        <SandBoxLazy2 />
      </Suspense>
    },
    {
      path: "/domain/es/sitemap.xml"
    },
    {
      path: "/domain/fr/sitemap.xml"
    },
    {
      path: "/domain/en/sitemap.xml"
    },
    {
      path: "/societe",
      element: <Suspense>
        <SocieteLazy />
      </Suspense>
    },
    {
      path: "/famille-de-produit",
      element: <Suspense>
        <FamilleLazy />
      </Suspense>
    },
    {
      path: "/famille-de-produit/:categorie",
      element: <Suspense>
        <ProduitLazy />
      </Suspense>
    },
    {
      path: "/famille-de-produit/:categorie/:modele",
      element: <Suspense>
        <FicheLazy />
      </Suspense>
    },
    {
      path: "/blog",
      element: <Suspense>
        <BlogLazy />
      </Suspense>
    },
    {
      path: "/blog/:article",
      element: <Suspense>
        <ArticleLazy />
      </Suspense>
    },
    {
      path: "/demander-un-devis",
      element: <Suspense>
        <DevisLazy />
      </Suspense>
    },
    {
      path: "/nous-contacter",
      element: <Suspense>
        <ContactLazy />
      </Suspense>
    },
    {
      path: "/nous-contacter/merci",
      element: <Suspense>
        <RemerciementLazy />
      </Suspense>
    },
    {
      path: "/politique-de-confidentialite",
      element: <Suspense>
        <PolitiqueLazy />
      </Suspense>
    },
    {
      path: "/mentions-legales",
      element: <Suspense>
        <MentionsLazy />
      </Suspense>
    },
    {
      path: "/politique-des-cookies",
      element: <Suspense>
        <CookiesLazy />
      </Suspense>
    },
    {
      path: "/empresa",
      element: <Suspense>
        <SocieteLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productos",
      element: <Suspense>
        <FamilleLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productos/:categorie",
      element: <Suspense>
        <ProduitLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productos/:categorie/:modele",
      element: <Suspense>
        <FicheLazy />
      </Suspense>
    },
    {
      path: "/blog",
      element: <Suspense>
        <BlogLazy />
      </Suspense>
    },
    {
      path: "/blog/:article",
      element: <Suspense>
        <ArticleLazy />
      </Suspense>
    },
    {
      path: "/solicitud-de-presupuesto",
      element: <Suspense>
        <DevisLazy />
      </Suspense>
    },
    {
      path: "/contacto",
      element: <Suspense>
        <ContactLazy />
      </Suspense>
    },
    {
      path: "/contacto/gracias",
      element: <Suspense>
        <RemerciementLazy />
      </Suspense>
    },
    {
      path: "/politica-de-confidencialidad",
      element: <Suspense>
        <PolitiqueLazy />
      </Suspense>
    },
    {
      path: "/informacion-legal",
      element: <Suspense>
        <MentionsLazy />
      </Suspense>
    },
    {
      path: "/politica-de-cookies",
      element: <Suspense>
        <CookiesLazy />
      </Suspense>
    },




    // EN
    {
      path: "/company",
      element: <Suspense>
        <SocieteLazy />
      </Suspense>
    },
    {
      path: "/product-family",
      element: <Suspense>
        <FamilleLazy />
      </Suspense>
    },
    {
      path: "/product-family/:categorie",
      element: <Suspense>
        <ProduitLazy />
      </Suspense>
    },
    {
      path: "/product-family/:categorie/:modele",
      element: <Suspense>
        <FicheLazy />
      </Suspense>
    },
    {
      path: "/blog",
      element: <Suspense>
        <BlogLazy />
      </Suspense>
    },
    {
      path: "/blog/:article",
      element: <Suspense>
        <ArticleLazy />
      </Suspense>
    },
    {
      path: "/request-a-quotation",
      element: <Suspense>
        <DevisLazy />
      </Suspense>
    },
    {
      path: "/contact-us",
      element: <Suspense>
        <ContactLazy />
      </Suspense>
    },
    {
      path: "/contact-us/thanks",
      element: <Suspense>
        <RemerciementLazy />
      </Suspense>
    },
    {
      path: "/privacy-policy",
      element: <Suspense>
        <PolitiqueLazy />
      </Suspense>
    },
    {
      path: "/legal-information",
      element: <Suspense>
        <MentionsLazy />
      </Suspense>
    },
    {
      path: "/cookie-policy",
      element: <Suspense>
        <CookiesLazy />
      </Suspense>
    },

    {
      path: "*",
      element: <Suspense>
        <ErrorBoundary />
      </Suspense>
    },


    // CAT
    {
      path: "/empresa",
      element: <Suspense>
        <SocieteLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productes",
      element: <Suspense>
        <FamilleLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productes/:categorie",
      element: <Suspense>
        <ProduitLazy />
      </Suspense>
    },
    {
      path: "/familia-de-productes/:categorie/:modele",
      element: <Suspense>
        <FicheLazy />
      </Suspense>
    },
    {
      path: "/sollicitud-de-pressupost",
      element: <Suspense>
        <DevisLazy />
      </Suspense>
    },
    {
      path: "/contacte",
      element: <Suspense>
        <ContactLazy />
      </Suspense>
    },
    {
      path: "/contacte/gracies",
      element: <Suspense>
        <RemerciementLazy />
      </Suspense>
    },
    {
      path: "/política-de-confidencialitat",
      element: <Suspense>
        <PolitiqueLazy />
      </Suspense>
    },
    {
      path: "/informació-legal",
      element: <Suspense>
        <MentionsLazy />
      </Suspense>
    },
    {
      path: "/política-de-cookies",
      element: <Suspense>
        <CookiesLazy />
      </Suspense>
    },
  ]);

  return (
    <RouterProvider router={router} errorElement={<ErrorBoundary />} />
  );
}

export default App;