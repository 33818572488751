import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { LanguageProvider } from './Contexte/LanguageContext';
import { TraductionProvider } from './Contexte/TraductionContext';



const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <LanguageProvider>
      <TraductionProvider>
      
        <HelmetProvider>
          <App />
        </HelmetProvider>
        
      </TraductionProvider>
    </LanguageProvider>
  </React.StrictMode>
);

reportWebVitals();
